import { TestLocators } from 'constants/Locators';

import { useNotInitialEffect } from 'hooks/useNotInitialEffect';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { SliderWithTextInput, SliderWithTextInputRef } from 'app/components/common/SliderWithTextInput';
import { EAdornmentPlacement } from 'app/components/common/SliderWithTextInput/types';
import {
  FRACTIONAL_STEP,
  MAX_LABEL,
  MAX_VALUE,
  MIN_LABEL,
  MIN_VALUE,
  STANDARD_STEP,
} from 'app/components/containers/layout/ConfigTabs/EconomicsControl/EconomicVariables/constants';
import { TOption } from 'app/components/containers/layout/ConfigTabs/EconomicsControl/EconomicVariables/types';
import { formatByLocale } from 'helpers/formatters';
import { translations } from 'locales/i18n';

import { useStyles } from './styles';

interface EconomicVariableSliderWithTextInputProps {
  label: string;
  testId: TestLocators;
  disabled?: boolean;
  isLoading?: boolean;
  value: number;
  options: TOption;
  onChange: (value: number) => void;
  allowFractional?: boolean;
  maximumDecimalPlaces?: number;
  adornmentSign?: string;
  adornmentPlacement?: EAdornmentPlacement;
}

export function EconomicVariableSliderWithTextInput({
  label,
  testId,
  disabled = false,
  isLoading = false,
  value,
  options,
  onChange,
  allowFractional = false,
  maximumDecimalPlaces,
  adornmentSign,
  adornmentPlacement,
}: EconomicVariableSliderWithTextInputProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const minLabel = options ? formatByLocale(options.min) : MIN_LABEL;
  const maxLabel = options ? formatByLocale(options.max) : MAX_LABEL;
  const minValue = options ? options.min : MIN_VALUE;
  const maxValue = options ? options.max : MAX_VALUE;
  const step = allowFractional ? FRACTIONAL_STEP : STANDARD_STEP;
  const sliderRef = useRef<SliderWithTextInputRef>(null);

  useNotInitialEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.setValue(value);
    }
  }, [value]);

  return (
    <div className={classes.label} data-testid={`${testId}_Wrapper`}>
      <SliderWithTextInput
        allowFractional={allowFractional}
        step={step}
        ref={sliderRef}
        label={t(label)}
        value={value}
        tooltipText={disabled ? t(translations.WELL_PLANNER.SCENARIO.READONLY_TOOLTIP_TEXT) : undefined}
        formatInputValue={formatByLocale}
        onChange={onChange}
        minValue={minValue}
        maxValue={maxValue}
        inputWidth="72px"
        minLabel={minLabel}
        maxLabel={maxLabel}
        isLoading={isLoading}
        testId={testId}
        disabled={disabled}
        maximumDecimalPlaces={maximumDecimalPlaces}
        adornmentSign={adornmentSign}
        adornmentPlacement={adornmentPlacement}
      />
    </div>
  );
}
