// Old Style Guide - https://www.figma.com/file/IKqajbr7BYPscS0KGHPB7X/IHS-Markit-Energy?node-id=25%3A101
// New Style Guide (rebranding) - https://www.figma.com/file/w3EPTc7e4h67s60qxLofS4/ENR-Rebranding-Specs?node-id=94%3A7018&t=Dp1BwF14Pft7yffZ-0

export const colors = {
  primary: '#D6002A',
  primaryHover: '#26364A',
  primaryWhite: '#FFFFFF',
  primaryBlue: '#0089D1',
  dayflower: '#369be6',
  palatinateBlue: '#3742e2',

  standardRed: '#ff0000',

  standardYellow: '#ffff00',

  baseGreen55: '#167527', // Oil (rebranding color)
  baseRed55: '#CC0000', // Gas (rebranding color)
  baseBlue55: '#0D71C6', // Water (rebranding color)
  baseOrange30: '#F89406', // Non-producing well status (rebranding color)

  stateWarningDefault: '#F89406', // rebranding color
  stateWarningOnDark: '#F89406', // rebranding color

  errorDefault: '#CC0000',
  errorOnDark: '#FF6E6B',

  baseBlack: '#000000',
  baseGrey05: '#F5F5F5', // rebranding color
  baseGrey10: '#E6E6E6', // rebranding color
  baseGray11: '#E3E3E3',
  grey12: '#E2E2E2',
  grey15: '#C7C7C7',
  baseGrey20: '#BFBFBF', // rebranding color
  baseGrey30: '#A7A7A7', // rebranding color
  baseGrey35: '#999999', // rebranding color
  grey40: '#555555',
  grey42: '#666666',
  grey44: '#707070',
  grey45: '#868686',
  grey48: '#313131',
  grey51: '#404040',
  grey53: '#444444',
  grey54: '#2E2E2E',
  grey55: '#2C2C2C',
  grey56: '#272727',
  grey60: '#252525',
  grey61: '#E5E5E5',
  grey62: '#3F3F46',
  grey78: '#C7C7C7',
  grey92: '#EBEBEB',
  grey93: '#0000001f',
  baseGrey57: '#262626',
  baseGrey65: '#525252', // rebranding color
  baseGrey80: '#333333', // rebranding color
  grey85: '#1E1E1E',
  grey90: '#1F1F1F',
  ebony: '#323336',
  smokeScreen: '#AEAEAE',
  industrialRevolution: '#737373',

  darkOrange: '#E68136',

  darkPurple: '#C156BB',
  purpleSpot: '#5c2dc2',

  green30: '#93C22D',
  aoifesGreen: '#2cb592',
  vibrantGreen: '#00cc00',
  kelpForest: '#4D7C0F',

  brightBlue: '#0066ff',

  darkYellow: '#E2D737',
  darkCharcole: '#333',

  darkPink: '#B52C4f',
  strongCyan: '#00b7bf',
  blueMagenta: '#484896',
  white: '#fff',
  white14: '#eeeeee14',
  darkViolet: '#6e599f',

  darkOverlay: 'rgba(0,0,0,0.5)',
  lightOverlay: 'rgba(255,255,255,0.8)',
  lightestOverlay: 'rgba(255,255,255,0.3)',

  // font
  textPrimaryDefault: '#000000',
  textInverseDefault: '#FFFFFF',
};
