import { ThemeMode } from 'impact-react-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const useStyles = makeStyles((theme) => {
  const { typography } = theme;
  const { type } = theme.palette;
  const isDarkTheme = type === ThemeMode.DARK;

  return createStyles({
    wrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: 0,
      right: 0,
    },
    card: {
      minWidth: '800px',
      background: isDarkTheme ? colors.grey60 : colors.primaryWhite,
      opacity: isDarkTheme ? '.85' : '1',
      padding: '24px',
      boxShadow: `0px 16px 16px 0 #00000024`,
      borderRadius: 1,
    },
    text: {
      color: isDarkTheme ? colors.primaryWhite : colors.grey40,
    },
    title: {
      fontWeight: typography.fontWeightRegular as number,
      fontSize: '24px',
      textAlign: 'center',
      marginBottom: 20,
    },
    infoWrapper: {
      marginTop: 24,
    },
    info: {
      fontSize: '16px',
      fontWeight: typography.fontWeightRegular as number,
    },
    link: {
      textTransform: 'none',
    },
    divider: {
      background: isDarkTheme ? colors.grey44 : colors.grey93,
      marginLeft: -24,
      marginRight: -24,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      color: isDarkTheme ? colors.primaryWhite : colors.grey40,
      position: 'relative',
      padding: '10px 24px',
      fontSize: '16px',
      '&:hover': {
        backgroundColor: isDarkTheme ? colors.white14 : colors.grey93,
      },
    },
  });
});
