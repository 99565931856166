import { PopoverProps } from '@material-ui/core';

import { CustomHeaderMenu } from 'app/components/common/CustomHeaderMenu';
import { ProfileMenuBody } from 'app/components/containers/layout/HeaderLandingPage/Profile/ProfileMenu/ProfileMenuBody';

export interface ProfileMenuProps {
  anchorEl: PopoverProps['anchorEl'];
  onClose: PopoverProps['onClose'];
}

export function ProfileMenu({ anchorEl, onClose }: ProfileMenuProps) {
  return (
    <CustomHeaderMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      <ProfileMenuBody onClose={onClose} />
    </CustomHeaderMenu>
  );
}
