import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types/RootState';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.landingPageShapefile || initialState;

export const selectFilename = createSelector([selectDomain], (state) => state.filename);
export const selectFileUrl = createSelector([selectDomain], (state) => state.url);
