import { TestLocators } from 'constants/Locators';

import { CircularProgress, InputLabel, TooltipProps as MuiTooltipProps } from '@material-ui/core';
import { Tooltip } from 'impact-react-components';
import { ChangeEvent, useCallback, useState } from 'react';

import { Autocomplete } from 'app/components/common/Autocomplete';
import { Input } from 'app/components/common/Input';
import { formatTestLocator } from 'helpers/formatters';

import { useStyles } from './styles';

export interface AutocompleteSelectProps<TValue> {
  value: TValue;
  options: TValue[];
  label?: string;
  getOptionLabel?(option: unknown): string;
  boldOptionsList?: string[];
  placeholder?: string;
  isLoading?: boolean;
  disableClearable?: boolean;
  readOnly?: boolean;
  selectOnFocus?: boolean;
  disabled?: boolean;
  onChange(value: TValue): void;
  testId?: TestLocators | string;
  tooltipText?: string;
  tooltipProps?: Partial<MuiTooltipProps>;
}

export function AutocompleteSelect<TValue>({
  value,
  options,
  label,
  getOptionLabel,
  boldOptionsList,
  placeholder,
  onChange,
  isLoading = false,
  readOnly = false,
  selectOnFocus = true,
  disableClearable = false,
  disabled = false,
  testId,
  tooltipText = '',
  tooltipProps,
}: AutocompleteSelectProps<TValue>) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const handleOnChange = useCallback(
    (event: ChangeEvent<{}>, newValue: any) => {
      onChange(newValue as TValue);
    },
    [onChange],
  );

  return (
    <div className={classes.wrapper} data-testid={`${testId}_${isOpen ? 'Open' : 'Close'}`}>
      {label ? (
        <InputLabel disableAnimation className={classes.label}>
          {label}
        </InputLabel>
      ) : null}
      <Autocomplete
        disabled={disabled}
        blurOnSelect
        disableClearable={disableClearable}
        openOnFocus
        value={value}
        loading={isLoading}
        selectOnFocus={selectOnFocus}
        options={options ?? []}
        open={isOpen}
        classes={{
          paper: classes.autocompletePaper,
          input: classes.autocompleteInput,
        }}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        onChange={handleOnChange}
        ListboxProps={{
          'data-testid': `${testId}_Dropdown`,
        }}
        renderOption={(option: any) => {
          const optionLabel = getOptionLabel ? getOptionLabel(option) : option;

          return (
            <span
              data-testid={formatTestLocator(`${testId}_Item`, optionLabel)}
              className={boldOptionsList && boldOptionsList.includes(optionLabel) ? classes.boldOption : ''}
            >
              {optionLabel}
            </span>
          );
        }}
        getOptionLabel={getOptionLabel}
        /* eslint-disable @typescript-eslint/no-unused-vars */
        renderInput={({ InputProps, InputLabelProps, ...params }) => (
          <Tooltip {...tooltipProps} title={tooltipText} open={tooltipText ? undefined : false}>
            <Input
              {...params}
              placeholder={placeholder}
              ref={InputProps.ref}
              readOnly={readOnly}
              endAdornment={
                <>
                  {isLoading && <CircularProgress className={classes.loader} color="inherit" size={15} />}
                  {InputProps.endAdornment}
                </>
              }
            />
          </Tooltip>
        )}
      />
    </div>
  );
}
