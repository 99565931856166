import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types/RootState';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.landingPageShapefileList || initialState;

export const selectData = createSelector([selectDomain], (state) => state.shapefiles);
export const selectRegion = createSelector([selectDomain], (state) => state.region);
export const selectStatus = createSelector([selectDomain], (state) => state.status);
export const selectError = createSelector([selectDomain], (state) => state.error);
