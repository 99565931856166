import { TestLocators } from 'constants/Locators';

import { Box, Divider, MenuItem, Typography } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import { CustomLink } from 'impact-react-components';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomHeaderMenu } from 'app/components/common/CustomHeaderMenu';
import { formatTestLocator } from 'helpers/formatters';
import { translations } from 'locales/i18n';
import { log } from 'services/log';
import { LogEvent } from 'services/LogEvent';
import { LogSource } from 'services/LogSource';

import { Contact } from './Contact';
import { useStyles } from './styles';

export interface HelpProps {
  anchorEl: HTMLElement | null;
  onClose(): void;
}

const helpItems = [
  { info: translations.HELP.LINKS.ABOUT_IMMERSE },
  {
    info: {
      ...translations.HELP.LINKS.DOCUMENTATION,
      URL: process.env.REACT_APP_IMPACT_DOCUMENTATION_URL as string,
    },
  },
  { info: translations.HELP.LINKS.DOCUMENTATION, divider: true },
  {
    info: {
      ...translations.HELP.LINKS.LEARNING_CENTER,
      URL: process.env.REACT_APP_LEARNING_CENTER_URL as string,
    },
  },
  { info: translations.HELP.LINKS.CUSTOMER_CARE_WEBSITE },
];

export function Help({ anchorEl, onClose }: HelpProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [copyStatus, setCopyStatus] = useState(t(translations.OPERATIONS.COPY));
  const contacts = useMemo(
    () => Object.keys(translations.HELP.CONTACTS).map((key, index) => <Contact index={index} key={key} />),
    [],
  );

  const handleCopyEmail = useCallback(() => {
    copy(t(translations.HELP.EMAIL_CUSTOMER_CARE.EMAIL));
    setCopyStatus(t(translations.OPERATIONS.COPIED));
    setTimeout(() => setCopyStatus(t(translations.OPERATIONS.COPY)), 2000);
  }, [t]);

  const handleClickOnLink = (itemName: string) => () => {
    log.info({
      event: LogEvent.CLICK,
      source: LogSource.HELP_MENU,
      itemName: itemName,
    });
  };

  return (
    <CustomHeaderMenu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      popperPlacement="bottom-start"
      menuListTestId={TestLocators.Header_Help_MenuList}
    >
      {helpItems.map(({ info, divider }) => {
        return divider ? (
          <Divider key={`divider_${info.TITLE}`} />
        ) : (
          <MenuItem key={info.TITLE} className={classes.menuItem} onClick={onClose}>
            <CustomLink
              className={classes.linkItem}
              onClick={handleClickOnLink(t(info.TITLE))}
              href={t(info.URL)}
              text={t(info.TITLE)}
              target="_blank"
              data-testid={formatTestLocator(TestLocators.Header_Help_MenuItem, t(info.TITLE))}
            />
          </MenuItem>
        );
      })}
      <MenuItem className={classes.menuItem}>
        <CustomLink
          className={classes.linkItem}
          onClick={handleClickOnLink(t(translations.HELP.EMAIL_CUSTOMER_CARE.TITLE))}
          href={`mailto:${t(translations.HELP.EMAIL_CUSTOMER_CARE.EMAIL)}`}
          text={t(translations.HELP.EMAIL_CUSTOMER_CARE.TITLE)}
          data-testid={formatTestLocator(
            TestLocators.Header_Help_MenuItem,
            t(translations.HELP.EMAIL_CUSTOMER_CARE.TITLE),
          )}
        />
        <Typography className={classes.copy} onClick={handleCopyEmail} variant="h4">
          {copyStatus}
        </Typography>
      </MenuItem>
      <Divider />
      <Box>{contacts}</Box>
    </CustomHeaderMenu>
  );
}
