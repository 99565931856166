import { TestLocators } from 'constants/Locators';

import { Container } from '@material-ui/core';
import { ReactElement } from 'react';

import { HeaderLandingPage } from '../HeaderLandingPage';
import { useStyles } from './styles';

export interface MainLayoutProps {
  children: ReactElement;
  testId?: TestLocators;
}

export function MainLayout({ children, testId }: MainLayoutProps) {
  const classes = useStyles();

  return (
    <>
      <HeaderLandingPage />
      <main className={classes.mainContainer}>
        <Container maxWidth={false} data-testid={testId} disableGutters>
          {children}
        </Container>
      </main>
    </>
  );
}
