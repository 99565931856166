import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'styles/theme/colors';

export const useStyles = makeStyles(() =>
  createStyles({
    priceFeedLabel: {
      color: colors.smokeScreen,
      lineHeigh: '15px',
    },
    divider: {
      margin: '2px 8px',
      height: '16px',
      backgroundColor: colors.ebony,
    },
  }),
);
